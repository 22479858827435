/*eslint-disable*/
import React from "react";
import { observer, inject } from 'mobx-react';
import PropTypes from "prop-types";
// react component used to create a calendar with events on it
import BigCalendar from "react-big-calendar";
// dependency plugin for react-big-calendar
import moment from "moment";
// react component used to create alerts
import SweetAlert from "react-bootstrap-sweetalert";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

import CloseIcon from '@material-ui/icons/Close';
import TimerIcon from '@material-ui/icons/Timer';

// core components
import Heading from "components/Heading/Heading.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Button from "components/CustomButtons/Button.jsx";
import buttonStyle from "assets/jss/material-dashboard-pro-react/components/buttonStyle.jsx";
import auth from 'utils/auth';

const styles = {
  ...buttonStyle,
  colorShow: {
    width: "15px",
    height: "15px",
    minWidth: "15px",
    padding: "0px"
  },
  orange: {
    backgroundColor: "#ff9800",
    boxShadow: "0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(255, 152, 0, 0.4)"
  },
  red: {
    backgroundColor: "#f44336",
    boxShadow: "0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(255, 152, 0, 0.4)"
  },
  azure: {
    backgroundColor: "#00bcd4",
    boxShadow: "0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(0, 188, 212, 0.4)"
  },
  green: {
    backgroundColor: "#4caf50",
    boxShadow: "0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(76, 175, 80, 0.4)"
  },
  statusLabel: {
    fontSize: "0.85em",
    marginRight: "10px"
  }
};

const localizer = BigCalendar.momentLocalizer(moment);

class Calendar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      alert: null,
      houseId: null
    };
    this.hideAlert = this.hideAlert.bind(this);
    this.cancelDetele = this.cancelDetele.bind(this);
  }

  async componentDidMount() {
    // if (auth.getUserRole() === 'user') {
    //   try {
    //     const userInfo = auth.getUserInfo();
    //     const data = await this.props.house.getHouseByOwner(userInfo.id);
    //     if(data && data.id){
    //       this.setState({houseId:data.id});
    //       await this.props.booking.loadBooking(data.id);
    //     }        
    //   } catch (error) {
    //     this.setState({
    //       alert: (
    //         <SweetAlert
    //           error
    //           style={{ display: "block", marginTop: "-100px" }}
    //           title={`Error !`}
    //           onConfirm={() => this.hideAlert()}
    //           confirmBtnCssClass={
    //             this.props.classes.button + " " + this.props.classes.success
    //           }
    //         >
    //           {`${error.message}`}
    //         </SweetAlert>
    //       )
    //     });
    //   }
    // } else {
    let id = this.props.match.params.id;
    try {
      if (id) {
        await this.props.booking.loadBooking(id);
      } else {
        await this.props.booking.loadBooking();
      }
    } catch (error) {
      this.setState({
        alert: (
          <SweetAlert
            error
            style={{ display: "block", marginTop: "-100px" }}
            title={`มีข้อผิดพลาด !`}
            confirmBtnText="ปิด"
            onConfirm={() => this.hideAlert()}
            confirmBtnCssClass={
              this.props.classes.button + " " + this.props.classes.success
            }
          >
            {`${error.message}`}
          </SweetAlert>
        )
      });
    }
    // }
  }
  selectedEvent(event) {
    // console.log('event',event);
    if (event && event.status === 'close') {
      this.setState({
        alert: (
          <SweetAlert
            warning
            showCancel
            style={{ display: "block", marginTop: "-100px" }}
            confirmBtnText="ตกลง"
            cancelBtnText="ยกเลิก"
            title="ต้องการเปิดจอง"
            confirmBtnCssClass={
              this.props.classes.button + " " + this.props.classes.success
            }
            cancelBtnCssClass={
              this.props.classes.button + " " + this.props.classes.danger
            }
            onConfirm={() => this.changeStatusBooking(event.id)}
            onCancel={() => this.cancelDetele()}
            focusCancelBtn
          >
          </SweetAlert>
        )
      });
    } else {
      if (auth.getUserRole() === 'super_admin' || auth.getUserRole() === 'admin') {
        this.props.history.push(`/admin/booking/${event.id}`);
      }
    }
  }

  addNewBooking(slotInfo) {
    // if (auth.getUserRole() === 'user') {
    //   let id = this.state.houseId;
    //   if (id) {
    //     this.setState({
    //       alert: (
    //         slotInfo.slots.length > 1 &&
    //         <SweetAlert
    //           warning
    //           showCancel
    //           style={{ display: "block", marginTop: "-100px" }}
    //           confirmBtnText="Confirm"
    //           title="ปิดการจอง"
    //           confirmBtnCssClass={
    //             this.props.classes.button + " " + this.props.classes.success
    //           }
    //           cancelBtnCssClass={
    //             this.props.classes.button + " " + this.props.classes.danger
    //           }
    //           onConfirm={() => this.createdBooking(slotInfo.start, slotInfo.end, id)}
    //           onCancel={() => this.cancelDetele()}
    //           focusCancelBtn
    //         >
    //           <form>
    //             <b>วันที่ </b> : {slotInfo.slots.length > 1 ?
    //               moment(slotInfo.start).format('Do MMMM YYYY') + ' ถึง ' + moment(slotInfo.end).format('Do MMMM YYYY') : moment(slotInfo.start).format('Do MMMM YYYY')}<br />
    //           </form>
    //         </SweetAlert>
    //       )
    //     });
    //   }
    // }else{
    let id = this.props.match.params.id;
    if (id) {
      this.setState({
        alert: (
          slotInfo.slots.length > 1 &&
          <SweetAlert
            warning
            // showCancel
            style={{ display: "block", marginTop: "-100px" }}
            // confirmBtnText="ปิดการจอง"
            // cancelBtnText="ปิด"
            title="ดำเนินการจอง"
            // confirmBtnCssClass={
            //   this.props.classes.button + " " + this.props.classes.rose
            // }
            // cancelBtnCssClass={
            //   this.props.classes.button + " " + this.props.classes.warning
            // }
            // onConfirm={() => this.createdBooking(slotInfo.start, slotInfo.end, id, 'close')}
            onCancel={() => this.cancelDetele()}
            focusCancelBtn
            showConfirm={false}>
            <form>

              <b>วันที่ </b> : {slotInfo.slots.length > 1 ?
                moment(slotInfo.start).format('Do MMMM YYYY') + ' ถึง ' + moment(slotInfo.end).format('Do MMMM YYYY') : moment(slotInfo.start).format('Do MMMM YYYY')}<br />
            </form>
            <br /><br />
            <Button
              onClick={() => this.cancelDetele()}
              color="danger">
              ยกเลิก
              </Button>
            <Button
              onClick={() => this.createdBooking(slotInfo.start, slotInfo.end, id, 'close')}
              color="warning">
              <CloseIcon />
              ปิดการจอง
              </Button>
            {auth.getUserRole() !== 'user' && <Button
              onClick={() => this.createdBooking(slotInfo.start, slotInfo.end, id, 'create')}
              color="success">
              {/* <CloseIcon/> */}
              <TimerIcon />
              จองที่พัก
              </Button>}
          </SweetAlert>
        )
      });
    }
    // }
  }

  async changeStatusBooking(id) {
    try {
      let houseId = this.props.match.params.id;
      const response = await this.props.booking.statusBooking(id, 'delete', houseId || null);
      if (response) {
        this.setState({
          alert: (
            <SweetAlert
              success
              style={{ display: "block", marginTop: "-100px" }}
              title="เสร็จสิ้น !"
              confirmBtnText="ตกลง"
              cancelBtnText="ปิด"
              onConfirm={() => this.hideAlert()}
              onCancel={() => this.hideAlert()}
              confirmBtnCssClass={
                this.props.classes.button + " " + this.props.classes.success
              }
            >
            </SweetAlert>
          )
        });
      } else {
        this.setState({
          alert: (
            <SweetAlert
              error
              confirmBtnText="ตกลง"
              cancelBtnText="ปิด"
              style={{ display: "block", marginTop: "-100px" }}
              title="มีข้อผิดพลาด !"
              onConfirm={() => this.hideAlert()}
              onCancel={() => this.hideAlert()}
              confirmBtnCssClass={
                this.props.classes.button + " " + this.props.classes.success
              }
            >
            </SweetAlert>
          )
        });
      }
    } catch (error) {
      this.setState({
        alert: (
          <SweetAlert
            error
            confirmBtnText="ตกลง"
            cancelBtnText="ปิด"
            style={{ display: "block", marginTop: "-100px" }}
            title={`มีข้อผิดพลาด !`}
            onConfirm={() => this.hideAlert()}
            onCancel={() => this.hideAlert()}
            confirmBtnCssClass={
              this.props.classes.button + " " + this.props.classes.success
            }
          >
            {`${error}`}
          </SweetAlert>
        )
      });
    }
  }

  async createdBooking(start, end, id, task) {
    if (task === 'close') {
      try {
        const response = await this.props.booking.closeBooking(id, start, end);
        if (response) {
          this.setState({
            alert: (
              <SweetAlert
                success
                style={{ display: "block", marginTop: "-100px" }}
                title="เสร็จสิ้น !"
                confirmBtnText="ตกลง"
                onConfirm={() => this.hideAlert()}
                onCancel={() => this.hideAlert()}
                confirmBtnCssClass={
                  this.props.classes.button + " " + this.props.classes.success
                }
              >
              </SweetAlert>
            )
          });
        } else {
          this.setState({
            alert: (
              <SweetAlert
                error
                confirmBtnText="ตกลง"
                cancelBtnText="ปิด"
                style={{ display: "block", marginTop: "-100px" }}
                title="มีข้อผิดพลาด !"
                onConfirm={() => this.hideAlert()}
                onCancel={() => this.hideAlert()}
                confirmBtnCssClass={
                  this.props.classes.button + " " + this.props.classes.success
                }
              >
              </SweetAlert>
            )
          });
        }
      } catch (error) {
        this.setState({
          alert: (
            <SweetAlert
              error
              confirmBtnText="ตกลง"
              cancelBtnText="ปิด"
              style={{ display: "block", marginTop: "-100px" }}
              title={`มีข้อผิดพลาด !`}
              onConfirm={() => this.hideAlert()}
              onCancel={() => this.hideAlert()}
              confirmBtnCssClass={
                this.props.classes.button + " " + this.props.classes.success
              }
            >
              {`${error}`}
            </SweetAlert>
          )
        });
      }
    } else if (task === "create") {
      try {
        const response = await this.props.booking.createTempBooking(id, start, end);
        if (response) {
          this.setState({
            alert: (
              <SweetAlert
                success
                confirmBtnText="ดำเนินการต่อ"
                cancelBtnText="ปิด"
                style={{ display: "block", marginTop: "-100px" }}
                title="เสร็จสิ้น !"
                onConfirm={() => window.location.href = `/admin/booking/${response.id}`}
                onCancel={() => this.hideAlert()}
                confirmBtnCssClass={
                  this.props.classes.button + " " + this.props.classes.success
                }
              >
              </SweetAlert>
            )
          });
        } else {
          this.setState({
            alert: (
              <SweetAlert
                error
                confirmBtnText="ตกลง"
                cancelBtnText="ปิด"
                style={{ display: "block", marginTop: "-100px" }}
                title="มีข้อผิดพลาด !"
                onConfirm={() => this.hideAlert()}
                onCancel={() => this.hideAlert()}
                confirmBtnCssClass={
                  this.props.classes.button + " " + this.props.classes.success
                }
              >
              </SweetAlert>
            )
          });
        }
      } catch (error) {
        this.setState({
          alert: (
            <SweetAlert
              error
              confirmBtnText="ตกลง"
              cancelBtnText="ปิด"
              style={{ display: "block", marginTop: "-100px" }}
              title={`มีข้อผิดพลาด !`}
              onConfirm={() => this.hideAlert()}
              onCancel={() => this.hideAlert()}
              confirmBtnCssClass={
                this.props.classes.button + " " + this.props.classes.success
              }
            >
              {`${error}`}
            </SweetAlert>
          )
        });
      }
    }
  }

  hideAlert() {
    this.setState({
      alert: null
    });
  }

  cancelDetele() {
    this.setState({
      alert: (
        <SweetAlert
          danger
          style={{ display: "block", marginTop: "-100px" }}
          title="Cancelled"
          onConfirm={() => this.hideAlert()}
          onCancel={() => this.hideAlert()}
          confirmBtnCssClass={
            this.props.classes.button + " " + this.props.classes.success
          }
        >
        </SweetAlert>
      )
    });
  }

  eventColors(event) {
    var backgroundColor = "event-";
    event.color
      ? (backgroundColor = backgroundColor + event.color)
      : (backgroundColor = backgroundColor + "default");
    return {
      className: backgroundColor
    };
  }
  render() {
    let { bookings } = this.props.booking;
    const { classes } = this.props;
    bookings = bookings.filter(val => (val.status === 'paid' || val.status === 'pending' || val.status === 'reject' || val.status === 'close'))
    bookings.map(val => {
      let peyment = (val.payments || []).filter(payment => payment.status === 'paid');
      const end =  moment(val.endDate).add(-1, 'days').format();
      val.title = val.status === 'paid' ? `จ่ายครบ : ${val.house.name}` : (val.status === 'cancel' || val.status === 'reject' || val.status === 'delete') ? `ยกเลิก : ${val.house.name}` : val.status === 'close' ? `ปิด : ${val.house.name}` : peyment.length > 0 ? `มัดจำ : ${val.house.name}` : `จอง : ${val.house.name}`
      val.allDay = false
      val.start = val.startDate
      val.end = end
      val.color = val.status === 'paid' ? 'green' : val.status === 'reject' ? 'default' : val.status === 'close' ? 'red' : peyment.length > 0 ? 'azure' : 'orange'
      return val
    });
    return (
      <div>
        <Heading
          textAlign="center"
          title="Booking Calendar"
          category={
            <span></span>
          }
        />
        {this.state.alert}
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={10}>
            <Card>
              <CardBody calendar>
                <BigCalendar
                  popup
                  selectable
                  localizer={localizer}
                  events={bookings}
                  defaultView="month"
                  scrollToTime={new Date(1970, 1, 1, 6)}
                  defaultDate={new Date()}
                  onSelectEvent={event => this.selectedEvent(event)}
                  onSelectSlot={slotInfo => this.addNewBooking(slotInfo)}
                  eventPropGetter={this.eventColors}
                />
              </CardBody>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={10}>
            <Button
              className={classes.colorShow + " " + classes.orange}
              justIcon
              round
            >
            </Button> <label className={classes.statusLabel}>จอง</label>
            <Button
              className={classes.colorShow + " " + classes.azure}
              justIcon
              round
            >
            </Button> <label className={classes.statusLabel}>มัดจำ</label>
            <Button
              className={classes.colorShow + " " + classes.green}
              justIcon
              round
            >
            </Button> <label className={classes.statusLabel}>จ่ายครบ</label>
            <Button
              className={classes.colorShow + " " + classes.red}
              justIcon
              round
            >
            </Button> <label className={classes.statusLabel}>ปิด</label>
            <Button
              className={classes.colorShow}
              justIcon
              round
            >

            </Button> <label className={classes.statusLabel}>ยกเลิก</label>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

Calendar.propTypes = {
  classes: PropTypes.object
};

export const page = inject('booking', 'house')(observer(Calendar));
export default withStyles(styles, { withTheme: true })(page);
