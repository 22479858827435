import Calendar from 'views/Calendar/Calendar.jsx';
// import Dashboard from 'views/Dashboard/Dashboard.jsx';
import User from 'views/User/User.jsx';
import Customers from 'views/Customer/Customer.jsx';
import UserPage from 'views/User/UserPage.jsx';
import House from 'views/House/House.jsx';
import Houses from 'views/House/Houses.jsx';
import BankAccounts from 'views/BankAccount/BankAccounts.jsx';
import BankAccount from 'views/BankAccount/BankAccount.jsx';
import IconHighlight from 'views/IconHighlight/IconHighlight.jsx';
import IconFacility from 'views/IconFacility/IconFacility.jsx';
import IconKitchen from 'views/IconKitchen/IconKitchen.jsx';
import PriceSettingPage from 'views/PriceSetting/PriceSettingPage.jsx';
import PriceCalendarPage from 'views/PriceCalendar/PriceCalendarPage.jsx';
// @material-ui/icons
// import Apps from "@material-ui/icons/Apps";
import EventBusyIcon from '@material-ui/icons/EventBusy';
import Contacts from '@material-ui/icons/Contacts';
import Settings from '@material-ui/icons/Settings';
// import DashboardIcon from '@material-ui/icons/Dashboard';
import DateRange from '@material-ui/icons/DateRange';
import Person from '@material-ui/icons/Person';
import AdminPanelSettings from '@material-ui/icons/AccountBox';
import Home from '@material-ui/icons/Home';
import BankIcon from '@material-ui/icons/AccountBalance';
import Collections from '@material-ui/icons/Collections';
import InsertChartOutlined from '@material-ui/icons/InsertChartOutlined';
import BookingEdit from 'views/Booking/BookingEdit';
import LoginPage from 'views/Pages/LoginPage.jsx';
import ErrorPage from 'views/Pages/ErrorPage.jsx';
import Holiday from 'views/Holiday/Holiday.jsx';
import ReportPage from 'views/Report/ReportPage';
import ContactPage from 'views/Contact/ContactPage';
import AppSettingPage from 'views/AppSetting/AppSetting';

var dashRoutes = [
  // {
  //   path: '/dashboard',
  //   name: 'Dashboard',
  //   icon: DashboardIcon,
  //   component: Dashboard,
  //   layout: '/admin',
  //   hidden: false,
  // },
  {
    collapse: true,
    hidden: true,
    name: 'Pages',
    icon: DateRange,
    state: 'pageCollapse',
    views: [
      {
        path: '/login-page',
        name: 'Login Page',
        component: LoginPage,
        layout: '/auth',
        hidden: true,
      },
      {
        path: '/error-page',
        name: 'Error Page',
        component: ErrorPage,
        layout: '/auth',
        hidden: true,
      },
    ],
  },
  {
    path: '/booking-calendar',
    name: 'Calendar',
    icon: DateRange,
    component: Calendar,
    layout: '/admin',
    hidden: false,
  },
  {
    path: '/holidays',
    name: 'Holiday',
    icon: EventBusyIcon,
    component: Holiday,
    layout: '/admin',
    hidden: false,
  },
  {
    path: '/booking/:id',
    name: 'Booking',
    icon: DateRange,
    component: BookingEdit,
    layout: '/admin',
    hidden: true,
  },
  {
    path: '/booking/bid/:bid',
    name: 'Booking',
    icon: DateRange,
    component: BookingEdit,
    layout: '/admin',
    hidden: true,
  },
  {
    path: '/user',
    name: 'Admin & House Owner',
    icon: AdminPanelSettings,
    component: User,
    layout: '/admin',
    hidden: false,
  },
  {
    path: '/customer',
    name: 'Customers',
    icon: Person,
    component: Customers,
    layout: '/admin',
    hidden: false,
  },
  {
    path: '/user/create',
    name: 'UserView',
    icon: Person,
    component: UserPage,
    layout: '/admin',
    hidden: true,
  },
  {
    path: '/bank-account',
    name: 'Bank Accounts',
    icon: BankIcon,
    component: BankAccounts,
    layout: '/admin',
    hidden: false,
  },
  {
    path: '/bank-account/create',
    name: 'Bank Account Create',
    icon: Home,
    component: BankAccount,
    layout: '/admin',
    hidden: true,
  },
  {
    path: '/bank-account/:id/edit',
    name: 'Bank Account Edit',
    icon: Home,
    component: BankAccount,
    layout: '/admin',
    hidden: true,
  },
  {
    path: '/house',
    name: 'Houses',
    icon: Home,
    component: Houses,
    layout: '/admin',
    hidden: false,
  },
  {
    path: '/house/create',
    name: 'HouseView',
    icon: Home,
    component: House,
    layout: '/admin',
    hidden: true,
  },
  {
    path: '/house/:id/edit',
    name: 'HouseView',
    icon: Home,
    component: House,
    layout: '/admin',
    hidden: true,
  },
  {
    path: '/house/calendar/:id',
    name: 'Calendar',
    icon: DateRange,
    component: Calendar,
    layout: '/admin',
    hidden: true,
  },
  {
    path: '/house/price-settings/:id',
    name: 'Price Setting',
    icon: Person,
    component: PriceSettingPage,
    layout: '/admin',
    hidden: true,
  },
  {
    path: '/house/price-calendar/:id',
    name: 'Price Calendar',
    icon: Person,
    component: PriceCalendarPage,
    layout: '/admin',
    hidden: true,
  },
  {
    path: '/user/:id',
    name: 'UserView',
    icon: Person,
    component: UserPage,
    layout: '/admin',
    hidden: true,
  },
  {
    path: '/user/:id/edit',
    name: 'UserView',
    icon: Person,
    component: UserPage,
    layout: '/admin',
    hidden: true,
  },
  {
    collapse: true,
    name: "Icons",
    icon: Collections,
    state: "iconsCollapse",
    hidden: false,
    layout: "/admin",
    views: [
      {
        path: "/icon-highlight",
        name: "Highlight",
        mini: "HI",
        component: IconHighlight,
        hidden: false,
        layout: "/admin"
      },
      {
        path: "/icon-facility",
        name: "Facility",
        mini: "FA",
        component: IconFacility,
        hidden: false,
        layout: "/admin"
      },
      {
        path: "/icon-kitchen",
        name: "Kitchen",
        mini: "KI",
        component: IconKitchen,
        hidden: false,
        layout: "/admin"
      }
    ]
  },
  {
    path: '/report',
    name: 'Report',
    icon: InsertChartOutlined,
    component: ReportPage,
    layout: '/admin',
    hidden: false,
  },
  {
    path: '/contact',
    name: 'Contact',
    icon: Contacts,
    component: ContactPage,
    layout: '/admin',
    hidden: false,
  },
  {
    path: '/settings',
    name: 'Settings',
    icon: Settings,
    component: AppSettingPage,
    layout: '/admin',
    hidden: false,
  },
];

export default dashRoutes;
