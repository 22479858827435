import React, { Component } from 'react';
import Modal from 'react-responsive-modal';
import FormControl from '@material-ui/core/FormControl';

import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import Button from 'components/CustomButtons/Button.jsx';
import DoneRoundedIcon from '@material-ui/icons/DoneRounded';

import '../styles/house.css';

class ModalHouseIcon extends Component {
  constructor(props) {
    super(props);
    this.state = {
      disableSelectBtn: false,
      currentIcons: [],
    };
  }

  componentWillReceiveProps = () => {
    const currentIcons = this.props.icons.map(item => {
      const iconSelect = this.props.iconsSelect.find(
        f => f.icon.url === item.url,
      );
      return {
        id: item.id,
        url: item.url,
        description: item.description,
        select: iconSelect ? true : false,
      };
    });

    this.setState({ currentIcons });
  };

  renderImages = () => {
    const currentIcons = this.state.currentIcons || [];

    if (currentIcons.length > 0) {
      return currentIcons.map((item, index) => {
        const imgClass = item.select ? 'image-selected' : 'image-select';
        return (
          <GridItem key={index} xs={4} md={2} lg={2}>
            <div
              className={imgClass}
              onClick={() => this.onImageClick(item.id, !item.select)}>
              {item.select && (
                <div className="check-icon">
                  <DoneRoundedIcon />
                </div>
              )}
              <img src={item.url} alt={index} style={{ width: '100%' }} />
            </div>
            <span>{item.description || ''}</span>

            <br />
          </GridItem>
        );
      });
    }
  };

  onImageClick = (id, select) => {
    const currentIcons = this.state.currentIcons || [];
    const icon = currentIcons.find(item => item.id === id);
    icon.select = select;
    this.setState({ currentIcons });
  };

  onSelectHouseIcon = () => {
    this.setState({ disableSelectBtn: true });

    const currentIcons = this.state.currentIcons || [];
    const iconsSelect = this.props.iconsSelect || [];
    const currentIconsSelect = currentIcons.filter(s => s.select);
    const resultIconsSelect = [];

    for (let i = 0; i < iconsSelect.length; i++) {
      const element = iconsSelect[i];
      const icon = currentIconsSelect.find(f => f.url === element.icon.url);

      if (!icon) {
        element.status = 'DELETE';
      } else {
        if (element.status === 'DELETE') {
          element.status = 'UPDATE';
        }
      }

      resultIconsSelect.push(element);
    }

    for (let i = 0; i < currentIconsSelect.length; i++) {
      const element = currentIconsSelect[i];
      const icon = iconsSelect.find(f => f.icon.url === element.url);

      if (!icon) {
        const icontWithOutDelete = resultIconsSelect.filter(
          h => h.status !== 'DELETE',
        );
        resultIconsSelect.push({
          icon: {
            id: element.id,
            url: element.url,
            description: element.description,
          },
          description: element.description,
          status: 'ADD',
          sequence: icontWithOutDelete.length + 1,
        });
      }
    }

    // Set Index Id and highlight
    for (let i = 0; i < resultIconsSelect.length; i++) {
      const element = resultIconsSelect[i];
      element.indexId = i;
      element.isHighlight = i === 0;
    }

    this.props.onHouseIconSelect(
      resultIconsSelect.sort((a, b) => a.sequence - b.sequence),
    );

    this.setState({ disableSelectBtn: false });
  };

  onCloseModal = () => {
    this.props.onCloseModal();
  };

  render() {
    const { isOpenModal } = this.props;

    return (
      <Modal open={isOpenModal} onClose={this.onCloseModal} center classNames={{
        modal: 'custom-modal',
      }}>
        <FormControl fullWidth>
          <GridContainer>
            <GridItem md={12}>
              <h2>House Icon</h2>
              <GridContainer>{this.renderImages()}</GridContainer>
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem md={6} align="right">
              <Button color="primary" onClick={this.onSelectHouseIcon}>
                Select
              </Button>
            </GridItem>
            <GridItem md={6} align="left">
              <Button
                disabled={this.state.disableSelectBtn}
                color="info"
                onClick={this.onCloseModal}>
                Close
              </Button>
            </GridItem>
          </GridContainer>
        </FormControl>
      </Modal>
    );
  }
}

export default ModalHouseIcon;
