import { extendObservable, toJS } from 'mobx';
import moment from 'moment-timezone';
import http from '../utils/http';
import { formatDate } from '../utils/datetime';

export class ReportStore {
  constructor() {
    extendObservable(this, {
      loading: true,
      houses: [],
      data: [],
    });
  }

  getData() {
    return toJS(this.data);
  }

  getHouseOptions() {
    const houses = toJS(this.houses);
    return (houses || []).map(h => ({ value: h.id, label: h.name }));
  }

  toQuery({ startDate, endDate, selectedHouses, createStart, createEnd, status }) {
    let houseIds = '';
    if (Array.isArray(selectedHouses) && selectedHouses.length) {
      houseIds = selectedHouses.map(opt => opt.value).join(',');
    }
    return {
      show_house: true,
      show_payment: true,
      start_date: formatDate(startDate),
      end_date: formatDate(endDate),
      house_ids: houseIds,
      create_start: formatDate(createStart),
      create_end: formatDate(createEnd),
      status: status
    };
  }

  transformReponse(res) {
    return (res || []).map(d => {
      // const sumPaymentAmount = d.payments.reduce((sum, payment) => {
      //   return sum + parseInt(payment.amount);
      // }, 0);

      return {
        createdAt: moment(d.created_at).format('DD/MM/YYYY, HH:mm:ss'),
        startDate: moment(d.start_date).format('DD/MM/YYYY, HH:mm:ss'),
        endDate: moment(d.end_date).format('DD/MM/YYYY, HH:mm:ss'),
        houseName: d.house.name || '',
        personAmount: +d.adults + +d.adults_kid || '',
        // deposit: sumPaymentAmount || '',
        // remaining: d.total_price - sumPaymentAmount || '',
        // insurance: d.house.insurance_price || '-',
        saleName: d.created_by || '',
        // totalAmount: parseInt(d.price || 0) + parseInt(d.house.insurance_price || 0),
        price: d.price,
        accountName: d.bank_account.account_name,
        bankName: d.bank_account.bank_name,
        accountNo: d.bank_account.account_no,
        totalPrice: d.total_price || 0
      };
    });
  }

  async loadHouses() {
    this.loading = true;
    try {
      const res = await http.get('/houses/dropdown');
      this.houses = res;
    } catch (error) {
      throw error;
    } finally {
      this.loading = false;
    }
  }

  async searchBookings(searchInfo) {
    this.loading = true;
    try {
      const query = this.toQuery(searchInfo);
      const res = await http.get('/bookings', query);
      this.data = this.transformReponse(res);
    } catch (error) {
      throw error;
    } finally {
      this.loading = false;
    }
  }
}

const reportStore = new ReportStore();
export default reportStore;
