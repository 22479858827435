import React, { Component } from "react";
import ReactDOM from "react-dom";
import Dropzone from "dropzone";
import "./styles/dropzone.css";
import auth from 'utils/auth';

const IconComponent = props => (
  <div data-filetype={props.filetype} className="filepicker-file-icon" />
);

// export interface IDropZoneProps {
//   url: string;
//   dirName: string;
//   token?: string;
//   acceptedFiles?: string;
//   placeholder?: string;
//   maxFileSize?: number;
//   config?: any;
//   className?: string;
//   id?: string;
//   onAllComplete?: any;
// }

export class DropZone extends Component {
  dropzone;
  state = {
    files: [],
    response: [],
    error: false,
    isFileUploaded: false,
  };

  async componentDidMount() {
    const dropzoneNode = ReactDOM.findDOMNode(this);
    const { url, acceptedFiles, placeholder } = this.props;
    const maxFileSize = this.props.maxFileSize || 10;
    const maxFiles = 1;
    const clickable = true;
    const token = auth.getToken();
    const headers = {
      'Authorization': `Bearer ${token}`,
    };
    const options = {
      url,
      maxFilesize: maxFileSize,
      maxFiles,
      clickable,
      autoQueue: false,
      parallelUploads: maxFileSize,
      autoProcessQueue: false,
      addRemoveLinks: true,
      // headers: this.setAuthorization(),
      acceptedFiles,
      dictDefaultMessage: placeholder || "Click here to upload a photo.",
      headers,
    };

    // dynamic(
    //   import('dropzone').then(m => {
    //     console.log('mdefault =---->');
    //     const mdefault = m.default;
    //     Dropzone = mdefault;
    //     Dropzone.autoDiscover = false;
    //     this.dropzone = new Dropzone(dropzoneNode, options);
    //     this.setupEvents();

    //     mdefault.__webpackChunkName = m.__webpackChunkName;
    //     return mdefault;
    //   }),
    //   {
    //     ssr: false,
    //   },
    // );

    // Dropzone = require("dropzone");
    Dropzone.autoDiscover = false;
    this.dropzone = new Dropzone(dropzoneNode, options);
    this.setupEvents();
  }

  getFiles() {
    return this.state.files;
  }

  setAuthorization() {
    const headers = {};
    if (this.props.token) {
      headers.Authorization = `Bearer ${this.props.token}`;
    }
    if (this.props.dirName) {
      headers.use_type = this.props.dirName;
    }
    if (this.props.eventId) {
      headers.event_id = this.props.eventId;
    }
    if (this.props.fileName) {
      headers.file_name = this.props.fileName;
    }

    return headers;
  }

  setupEvents() {
    const self = this;
  
    // Event เมื่อเพิ่มไฟล์
    this.dropzone.on("addedfile", file => {
      if (!file) return;
  
      self.setState({ error: false });
      self.setState({ isFileUploaded: true });
      const files = self.state.files || [];
      files.push(file);
      self.setState({ files });
    });
  
    // Event เมื่อเอาไฟล์ออก
    this.dropzone.on("removedfile", file => {
      if (!file) return;
  
      const files = self.state.files || [];
      const index = files.findIndex(
        fileInFiles => fileInFiles.name === file.name && fileInFiles.size === file.size
      );
      if (index > -1) files.splice(index, 1);
      self.setState({ isFileUploaded: files.length > 0 });
      self.setState({ files });
    });
  
    // จำกัดจำนวนไฟล์
    this.dropzone.on("maxfilesexceeded", file => {
      this.dropzone.removeAllFiles();
      this.dropzone.addFile(file);
    });
  
    // กำหนด onError สำหรับข้อผิดพลาดทั่วไป
    this.dropzone.on("error", (file, msg) => {
      if (!file) return;
  
      self.setState({ error: true });
    });
  
    // Event เมื่ออัปโหลดสำเร็จ
    this.dropzone.on("success", (file, res) => {
      let result = self.state.response || [];
      if (!Array.isArray(result)) result = [];
  
      const data = result.find(r => r.uuid === file.upload.uuid);
      if (data) {
        data.file = file;
        data.res = res;
      } else {
        result.push({ file, res });
      }
  
      self.setState({ response: result }, () => {
        if (self.props.onComplete) self.props.onComplete(file, result);
      });
    });
  
    // Event เมื่อคิวทั้งหมดเสร็จสมบูรณ์
    this.dropzone.on("queuecomplete", () => {
      if (self.props.onAllComplete && !self.state.error) {
        self.props.onAllComplete(self.state.response);
        this.dropzone.removeAllFiles();
        self.setState({ isFileUploaded: false });
      }
    });
  }

  // upload() {
  //   if (!this.state.isFileUploaded) {
  //     // แจ้งเตือนหากยังไม่มีไฟล์อัปโหลด
  //     if (this.props.onError) {
  //       this.props.onError("กรุณาอัปโหลดรูปภาพก่อนส่งข้อมูล");
  //     }
  //     return;
  //   }

  //   this.dropzone.options.url = `${this.dropzone.options.url}?use_type=${this.props.dirName}`;
  //   this.setState({ response: [] }, () => {
  //     const files = this.dropzone.getAcceptedFiles();
  //     const data = files.map(file => ({ uuid: file.upload.uuid }));
  //     this.setState({ response: data });
  //     this.dropzone.processQueue();
  //   });
  // }

  upload() {

    if (!this.state.isFileUploaded) {
      // แจ้งเตือนหากยังไม่มีไฟล์อัปโหลด
      if (this.props.onError) {
        this.props.onError("กรุณาอัปโหลดรูปภาพก่อนส่งข้อมูล");
      }
      return;
    }

    this.dropzone.options.url = `${this.dropzone.options.url}?use_type=${this.props.dirName}`;
    this.setState(
      {
        response: []
      },
      () => {
        const files = this.dropzone.getAcceptedFiles();
        const data = [];
        files.forEach(file => {
          data.push({
            uuid: file.upload.uuid
          });
          this.dropzone.enqueueFile(file);
        });
        this.setState({
          response: data
        });
        this.dropzone.processQueue();
      }
    );
  }

  render() {
    const icons = [];
    const { files } = this.state;
    const { config, id } = this.props;
    const className = this.props.className
      ? `filepicker rounded dropzone ${this.props.className}`
      : "filepicker rounded dropzone";

    const options = {};
    if (id) {
      options.id = id;
    }

    if (
      config &&
      config.showFiletypeIcon &&
      config.iconFiletypes &&
      (!files || files.length < 1)
    ) {
      for (let i = 0; i < config.iconFiletypes.length; i += 1) {
        icons.push(
          <IconComponent
            filetype={config.iconFiletypes[i]}
            key={`icon-component-${i}`}
          />
        );
      }
    }

    return (
      <div {...options} className={className}>
        {" "}
        {icons} {this.props.children}{" "}
      </div>
    );
  }
}

export default DropZone;
