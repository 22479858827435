import { extendObservable, toJS } from 'mobx';
// import config from 'config';
// import camelcaseKeys from 'camelcase-keys';
// import snakecaseKeys from 'snakecase-keys';
// import auth from '../utils/auth';
import http from '../utils/http';

export class CustomerStore {
  constructor() {
    extendObservable(this, {
      loading: false,
      customers: [],
      mode: 'create',
    });
  }

  setCustomers(customers) {
    this.customers = customers;
  }

  getCustomersJS() {
    return toJS(this.customers);
  }

  async loadCustomer() {
    this.loading = true;
    try {
      const customers = await http.get('/customers');
      this.setCustomers(customers ? customers.filter(customer => customer.status !== 'deleted') : []);
    } catch (error) {
      throw error;
    } finally {
      this.loading = false;
    }
  }

  // resetUser() {
  //   this.user = initialCustomer;
  //   this.mode = 'create';
  // }

}

const customerStore = new CustomerStore();
export default customerStore;
