import { extendObservable, toJS } from 'mobx';
import config from 'config';
// import camelcaseKeys from 'camelcase-keys';
import snakecaseKeys from 'snakecase-keys';
import httpClient from 'utils/http';

const initialIcon = {
  id: 0,
  url: '',
  description: '',
  type: ''
};

export class IconStore {
  constructor() {
    extendObservable(this, {
      loading: false,
      icon: initialIcon,
      iconEditing: initialIcon,
      icons: [],
    });
  }

  getIconJS() {
    return toJS(this.icon);
  }

  getIconEditingJS() {
    return toJS(this.iconEditing);
  }

  getIconsJS() {
    return toJS(this.icons);
  }

  getIconJSById(id) {
    const icons = toJS(this.icons);
    return icons.find(f => f.id === id);
  }

  setIcon(icon) {
    this.icon = icon;
  }

  setIconEditing(icon) {
    this.iconEditing = icon;
  }

  setIcons(icons) {
    this.icons = icons;
  }

  setIconProp(key, value) {
    this.icon[key] = value;
  }

  setIconEditingProp(key, value) {
    this.iconEditing[key] = value;
  }

  clearIcon() {
    this.icon = initialIcon;
  }

  clearIconEditing() {
    this.iconEditing = initialIcon;
  }

  updateIconsJS(iconEditing) {
    const icon = this.icons.find(f => f.id === iconEditing.id);
    icon.description = iconEditing.description;
  }

  // async fetchIcons() {
  //   this.loading = true;
  //   try {
  //     const response = await httpClient.get(`/icons`);
  //     this.setIcons(response.filter(f => f.url !== '') || []);
  //   } catch (error) {
  //     console.log(error.message);
  //     throw error;
  //   } finally {
  //     this.loading = false;
  //   }
  // }

  async fetchIcons(type) {
    this.loading = true;
    try {
      const response = await httpClient.get(`/icons?type=${type}`);
      this.setIcons(response.filter(f => f.url !== '') || []);
    } catch (error) {
      console.log(error.message);
      throw error;
    } finally {
      this.loading = false;
    }
  }

  async saveIcon(type,icon) {
    try {
      this.loading = true;
      const data = icon ? icon : snakecaseKeys(this.icon);
      data.type= type
      let response;
      let url = `${config.api.url}/icons`;

      if (
        data.id !== null &&
        data.id !== undefined &&
        data.id !== '' &&
        data.id !== 0
      ) {
        console.log('## Update Icon');
        response = await httpClient.put(url + `/${data.id}`, data);
      } else {
        console.log('## Insert Icon');
        response = await httpClient.post(url, data);
      }

      return response;
    } catch (error) {
      // throw error;
      console.log('## Error when call AddIcon(): ', error);
      throw new Error(error.response.data.code);
    } finally {
      this.loading = false;
    }
  }

  async deleteIcon(id) {
    this.loading = true;
    try {
      const response = await httpClient.delete(`/icons/${id}`);
      if (response && response.status === 'success') {
        this.icons = this.icons.filter(val => val.id !== id);
      }
      return response;
    } catch (error) {
      throw error;
    } finally {
      this.loading = false;
    }
  }
}

const iconStore = new IconStore();
export default iconStore;
