import { extendObservable, toJS } from 'mobx';
import http from '../utils/http';
import camelcaseKeys from 'camelcase-keys';
import snakecaseKeys from 'snakecase-keys';

export class SettingStore {
  constructor() {
    extendObservable(this, {
      loading: true,
      config: {},
    });
  }

  setConfig(config) {
    this.config = config || {};
  }

  getConfig() {
    return toJS(this.config);
  }

  setConfigValue(key, value) {
    this.config[key] = value;
  }

  async loadVatConfig() {
    this.loading = true;
    try {
      const config = await http.get(`/configs/type/vat_percent`);
      if (config) {
        this.setConfig(camelcaseKeys(config));
      }
    } catch (error) {
      throw error;
    } finally {
      this.loading = false;
    }
  }

  toNumberVat(data = {}) {
    if (data.valueA) {
      data.valueA = +data.valueA;
    }
  }

  async saveVatConfig() {
    this.loading = true;
    const data = snakecaseKeys(this.getConfig());
    this.toNumberVat(data);
    try {
      await http.put(`/configs/${data.id}`, data);
    } catch (error) {
      throw error;
    } finally {
      this.loading = false;
    }
  }
}

const settingStore = new SettingStore();
export default settingStore;
