import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer, inject } from 'mobx-react';
import withStyles from '@material-ui/core/styles/withStyles';
import FormLabel from '@material-ui/core/FormLabel';
import InputAdornment from '@material-ui/core/InputAdornment';

// material ui icons
import CloseIcon from '@material-ui/icons/Close';
import BankIcon from '@material-ui/icons/AccountBalance';

// core components
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import CustomInput from 'components/CustomInput/CustomInput.jsx';
import Button from 'components/CustomButtons/Button.jsx';
import Card from 'components/Card/Card.jsx';
import CardHeader from 'components/Card/CardHeader.jsx';
import CardIcon from 'components/Card/CardIcon.jsx';
import CardBody from 'components/Card/CardBody.jsx';
import CardFooter from 'components/Card/CardFooter.jsx';

// style for this view
import validationFormsStyle from 'assets/jss/material-dashboard-pro-react/views/validationFormsStyle.jsx';
import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx';

import sweetAlertStyle from 'assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx';

import Swal from 'sweetalert2';

// Util
import validateControl, { VALIDATE_TYPES } from '../../utils/validateControl';

class AppSetting extends Component {
  constructor(props) {
    super(props);
    this.state = {
      vatState: '',
    };
  }

  componentDidMount = async () => {
    try {
      this.props.setting.loadVatConfig();
    } catch (error) {
      Swal.fire({
        type: 'error',
        title: error.message,
      });
    }
  };

  validationForm = () => {
    const { vatState } = this.state;
    let statusValidation = true;

    if (vatState === '' || vatState !== 'success') {
      const error = validateControl.getErrorText(
        VALIDATE_TYPES.EMPTY,
      );

      this.setState((prev) => ({ ...prev, vatState: error }));

      statusValidation = false;
    }

    return statusValidation;
  };

  onTextFieldChange = (value, key, types, maxLength) => {
    const result = validateControl.validate(value, types, maxLength);

    if (result.length > 0) {
      const error = validateControl.getErrorText(
        result[0],
        maxLength,
      );
      this.setState((prev) => ({ ...prev, vatState: error }));
    } else {
      this.setState((prev) => ({ ...prev, vatState: 'success' }));
    }

    this.props.setting.setConfigValue(key, value);
  };

  submitClick = async () => {
    try {
      if (this.validationForm()) {
        this.props.setting.saveVatConfig();

        Swal.fire({
          type: 'success',
          title: 'บันทึก',
          text: 'การบันทึกข้อมูลสำเร็จ',
        }).then(r => {
          if (r.value) {
            setTimeout(() => {
              window.location.reload();
            }, 1000);
          }
        });
      }
    } catch (error) {
      Swal.fire({
        type: 'error',
        title: 'บันทึก',
        text: 'บันทึกไม่สำเร็จ ระบบผิดพลาด',
      });
    }
  };

  render() {
    const { classes } = this.props;
    const { vatState } = this.state;
    const config = this.props.setting.getConfig();
    console.log('config ->', config);
    return (
      <React.Fragment>
        {/* Name, Owner, Status */}
        <GridContainer>
          <GridItem xs={12} sm={12}>
            <Card>
              <CardHeader color="primary" icon>
                <CardIcon color="primary">
                  <BankIcon />
                </CardIcon>
                <h4 className={classes.cardIconTitle}>ตั้งค่าระบบ</h4>
              </CardHeader>
              <CardBody>
                <form>
                  <GridContainer>
                    <GridItem xs={12} sm={2}>
                      <FormLabel className={classes.labelHorizontal}>
                        VAT
                      </FormLabel>
                    </GridItem>
                    <GridItem xs={12} sm={4}>
                      <CustomInput
                        id="CustomInput"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        success={vatState === 'success'}
                        error={vatState !== 'success'}
                        helperText={
                          vatState === 'success'
                            ? ''
                            : vatState
                        }
                        inputProps={{
                          onChange: event =>
                            this.onTextFieldChange(
                              event.target.value,
                              'valueA',
                              [
                                VALIDATE_TYPES.EMPTY,
                                VALIDATE_TYPES.MAX_LENGTH,
                                VALIDATE_TYPES.NUMBER,
                              ],
                              3,
                            ),
                          type: 'text',
                          value: config.valueA,
                          name: 'vat',
                          endAdornment:
                            vatState === 'success' ||
                            vatState === '' ? (
                              undefined
                            ) : (
                              <InputAdornment position="end">
                                <CloseIcon className={classes.danger} />
                              </InputAdornment>
                            ),
                        }}
                      />
                    </GridItem>
                  </GridContainer>
                </form>
              </CardBody>
              <CardFooter className={classes.justifyContentCenter}>
                <Button color="primary" onClick={this.submitClick}>บันทึก</Button>
              </CardFooter>
            </Card>
          </GridItem>
        </GridContainer>
      </React.Fragment>
    );
  }
}

AppSetting.propTypes = {
  classes: PropTypes.object,
};

export const page = inject('setting')(observer(AppSetting));
export default withStyles(
  validationFormsStyle,
  sweetAlertStyle,
  extendedFormsStyle,
)(page);
