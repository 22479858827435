import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer, inject } from 'mobx-react';

//Material Component
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import CustomInput from 'components/CustomInput/CustomInput';
import Button from 'components/CustomButtons/Button.jsx';
import Card from 'components/Card/Card.jsx';
import CardHeader from 'components/Card/CardHeader.jsx';
import CardIcon from 'components/Card/CardIcon.jsx';
import CardBody from 'components/Card/CardBody.jsx';

// Other Component
import Modal from 'react-responsive-modal';

//Material Core
import { withStyles } from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';

// Material ui icons
import CloseIcon from '@material-ui/icons/Close';
import AddPhotoAlternateIcon from '@material-ui/icons/AddPhotoAlternate';

// Config
import config from 'config';

// Util
import validateControl, { VALIDATE_TYPES } from '../../utils/validateControl';
import Swal from 'sweetalert2';
import DropZone from '../../components/DropZone';

//Theme
import validationFormsStyle from 'assets/jss/material-dashboard-pro-react/views/validationFormsStyle.jsx';
import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx';
import sweetAlertStyle from 'assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx';

//Style
import './styles/house_icon.css';

import ImageIcon from './component/ImageIcon.jsx';

const dropZoneOption = {
  acceptFile: '.jpg,.png,.gif,.jpeg',
  maxSize: 10,
  limitationText:
    'สามารถอัพโหลดไฟล์นามสกุล (jpg, png, gif) ครั้งละ 1 ไฟล์และขนาดไม่เกิน 10 MB',
};

class HouseIcons extends Component {
  defaultIconState = {
    description: 'success',
  };

  defaultIconEditingState = {
    description: 'success',
  };

  constructor(props) {
    super(props);
    this.state = {
      isIconUploading: false,
      iconState: this.defaultIconState,
      iconEditingState: this.defaultIconEditingState,
      houseIconModalOpen: false,
    };
  }

  componentDidMount = () => {
    try {
      this.props.icon.fetchIcons('highlight');
    } catch (error) {
      Swal.fire({
        type: 'error',
        title: error.message,
      });
    }
  };

  onIconUpload = () => {
    this.setState({ isIconUploading: true }, () => {
      this.iconDropZone.upload();
    });
  };

  onIconUploadComplete = async files => {
    const url = files[0].res.url;
    this.props.icon.setIconProp('url', url);

    try {
      await this.props.icon.saveIcon('highlight');
      this.props.icon.clearIcon();
      this.props.icon.fetchIcons('highlight');
    } catch (error) {
    } finally {
      this.setState({
        isIconUploading: false,
      });
    }
  };

  onTextIconChange = (value, stateName, types, maxLength) => {
    const currentIconState = this.state.iconState;
    const result = validateControl.validate(value, types, maxLength);

    if (result.length > 0) {
      currentIconState[stateName] = validateControl.getErrorText(
        result[0],
        maxLength,
      );
    } else {
      currentIconState[stateName] = 'success';
    }

    this.props.icon.setIconProp(stateName, value);

    this.setState({
      iconState: currentIconState,
    });
  };

  onTextIconEditingChange = (value, stateName, types, maxLength) => {
    const currentIconState = this.state.iconEditingState;
    const result = validateControl.validate(value, types, maxLength);

    if (result.length > 0) {
      currentIconState[stateName] = validateControl.getErrorText(
        result[0],
        maxLength,
      );
    } else {
      currentIconState[stateName] = 'success';
    }

    this.props.icon.setIconEditingProp(stateName, value);

    this.setState({
      iconEditingState: currentIconState,
    });
  };

  ////////////// House Icon Modal Event

  onHouseIconModalOpen = icon => {
    this.props.icon.setIconEditing(icon);
    this.setState({
      houseIconModalOpen: true,
    });
  };

  onHouseIconModalClose = () => {
    this.setState({
      houseIconModalOpen: false,
      iconState: this.defaultIconEditingState,
    });
  };

  onHouseIconModalDelete = (id, desc) => {
    Swal.fire({
      title: 'ยืนยันการลบไอคอน',
      text: `คุณแน่ใจว่าต้องการลบไอคอน "${desc}" นี้?`,
      showCancelButton: true,
      confirmButtonColor: '#9c27b0',
      // cancelButtonColor: '#d33',
      confirmButtonText: 'ใช่, ฉันต้องการลบ',
      cancelButtonText: 'ไม่ใช่',
    }).then(async result => {
      if (result.value) {
        const result = await this.props.icon.deleteIcon(id);

        if (result && result.status === 'success') {
          Swal.fire(
            'ลบสำเร็จ!',
            `คุณได้ทำการลบ "${desc}" เรียบร้อย`,
            'success',
          );
        } else {
          let msg = 'ไม่สามารถทำการลบได้ เนื่องจากยังมีบ้านพักใช้ไอคอนนี้อยู่';

          for (let i = 0; i < result.houses.length; i++) {
            const house = result.houses[i];
            msg += '<br />';
            msg += `"${house.name}"`;
          }

          Swal.fire('ลบไม่สำเร็จ', msg, 'warning');
        }
      }
    });
  };

  onIconEditingSave = async () => {
    const icon = this.props.icon.getIconEditingJS();
    
    // ตรวจสอบว่าอัปโหลดไฟล์แล้วและคำบรรยายไม่เกิน 99 ตัวอักษร
    if (!icon.url) {
      Swal.fire({
        type: 'error',
        title: 'การบันทึก',
        text: 'กรุณาอัปโหลดไฟล์ไอคอนก่อน',
      });
      return;
    }
  
    if (icon.description.length > 99) {
      Swal.fire({
        type: 'error',
        title: 'การบันทึก',
        text: 'คำบรรยายต้องไม่เกิน 99 ตัวอักษร',
      });
      return;
    }
  
    try {
      const result = await this.props.icon.saveIcon('highlight', icon);
  
      if (result && result.id) {
        Swal.fire({
          type: 'success',
          title: 'บันทึก',
          text: 'การบันทึกข้อมูลสำเร็จ',
        }).then(r => {
          this.props.icon.updateIconsJS(result);
        });
      } else {
        Swal.fire({
          type: 'error',
          title: 'บันทึก',
          text: 'การบันทึกข้อมูลไม่สำเร็จ',
        });
      }
    } catch (error) {
      // Handle any additional error
    } finally {
      this.onHouseIconModalClose();
    }
  };

  ////////////// Icon Editing Modal Save
  // onIconEditingSave = async () => {
  //   try {
  //     const icon = this.props.icon.getIconEditingJS();
  //     const result = await this.props.icon.saveIcon('highlight',icon);

  //     if (result && result.id) {
  //       Swal.fire({
  //         type: 'success',
  //         title: 'บันทึก',
  //         text: 'การบันทึกข้อมูลสำเร็จ',
  //       }).then(r => {
  //         this.props.icon.updateIconsJS(result);
  //       });
  //     } else {
  //       Swal.fire({
  //         type: 'error',
  //         title: 'บันทึก',
  //         text: 'การบันทึกข้อมูลไม่สำเร็จ',
  //       });
  //     }
  //   } catch (error) {
  //   } finally {
  //     this.onHouseIconModalClose();
  //   }
  // };

  render() {
    const { classes } = this.props;
    const {
      iconState,
      iconEditingState,
      isIconUploading,
      houseIconModalOpen,
    } = this.state;
    const icon = this.props.icon.getIconJS();
    const iconEditing = this.props.icon.getIconEditingJS();
    const icons = this.props.icon.getIconsJS() || [];

    return (
      <React.Fragment>
        <FormControl fullWidth>
          <GridContainer>
            <GridItem md={12}>
              <Card>
                <CardHeader color="primary" icon>
                  <CardIcon color="primary">
                    <AddPhotoAlternateIcon />
                  </CardIcon>
                  <h4 className={classes.cardIconTitle}>เพิ่มไอคอน</h4>
                </CardHeader>
                <CardBody>
                  <DropZone
                    ref={e => {
                      this.iconDropZone = e;
                    }}
                    placeholder="คลิก หรือ ลากไฟมาที่นี่เพื่อทำการอัพโหลด"
                    url={`${config.api.url}/uploads`}
                    acceptedFiles={dropZoneOption.acceptFile}
                    maxFileSize={dropZoneOption.maxSize}
                    dirName="house_images"
                    // fileName=""
                    onAllComplete={this.onIconUploadComplete}
                  />
                  <FormLabel
                    style={{
                      fontSize: '14px',
                      fontWeight: '400',
                      paddingTop: '30px',
                      color: 'rgba(0, 0, 0, 0.26)',
                      display: 'inline-flex',
                    }}>
                    คำบรรยายไอคอน
                  </FormLabel>
                  <CustomInput
                    id="description"
                    success={iconState.description === 'success'}
                    error={iconState.description !== 'success'}
                    helperText={
                      iconState.description === 'success'
                        ? ''
                        : iconState.description
                    }
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      onChange: event =>
                        this.onTextIconChange(
                          event.target.value,
                          'description',
                          [VALIDATE_TYPES.MAX_LENGTH, VALIDATE_TYPES.EMPTY],
                          99,
                        ),
                      type: 'text',
                      value: `${icon.description || ''}`,
                      name: 'description',
                      endAdornment:
                        iconState.description === 'success' ||
                        iconState.description === '' ? (
                          undefined
                        ) : (
                          <InputAdornment position="end">
                            <CloseIcon className={classes.danger} />
                          </InputAdornment>
                        ),
                    }}
                  />
                  <Button
                    disabled={isIconUploading}
                    fullWidth
                    color="primary"
                    onClick={this.onIconUpload}>
                    อัพโหลดไอคอน
                  </Button>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
          <Card>
            <CardHeader color="primary" icon>
              {/* <CardIcon color="primary">
                    <AddPhotoAlternateIcon />
                  </CardIcon>
                  <h4 className={classes.cardIconTitle}>Add House Icon</h4> */}
            </CardHeader>
            <CardBody>
              <ImageIcon
                images={icons}
                onIconEditClick={this.onHouseIconModalOpen}
                onIconDeleteClick={(id, desc) =>
                  this.onHouseIconModalDelete(id, desc)
                }
              />
            </CardBody>
          </Card>
        </FormControl>

        <Modal
          open={houseIconModalOpen}
          onClose={this.onHouseIconModalClose}
          center>
          <FormControl fullWidth>
            <h2>คำบรรยายไอคอน</h2>
            <CustomInput
              id="description"
              success={iconEditingState.description === 'success'}
              error={iconEditingState.description !== 'success'}
              helperText={
                iconEditingState.description === 'success'
                  ? ''
                  : iconEditingState.description
              }
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                onChange: event =>
                  this.onTextIconEditingChange(
                    event.target.value,
                    'description',
                    [VALIDATE_TYPES.MAX_LENGTH, VALIDATE_TYPES.EMPTY],
                    99,
                  ),
                type: 'text',
                value: `${iconEditing.description || ''}`,
                name: 'description',
                endAdornment:
                  iconEditingState.description === 'success' ||
                  iconEditingState.description === '' ? (
                    undefined
                  ) : (
                    <InputAdornment position="end">
                      <CloseIcon className={classes.danger} />
                    </InputAdornment>
                  ),
              }}
            />
            <Button color="primary" onClick={this.onIconEditingSave}>
              บันทึก
            </Button>
          </FormControl>
        </Modal>
      </React.Fragment>
    );
  }
}

HouseIcons.propTypes = {
  classes: PropTypes.object,
};

export const page = inject('icon')(observer(HouseIcons));
export default withStyles(
  validationFormsStyle,
  sweetAlertStyle,
  extendedFormsStyle,
)(page);
