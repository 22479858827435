import React from "react";
import PropTypes from "prop-types";
// react component for creating dynamic tables
import ReactTable from "react-table";
import { observer, inject } from 'mobx-react';
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons
// import Add from "@material-ui/icons/Add";
import Person from "@material-ui/icons/Person";
// import Edit from "@material-ui/icons/Edit";
// import Visibility from "@material-ui/icons/Visibility";
// import Close from "@material-ui/icons/Close";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
// import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
// import { Tooltip } from '@material-ui/core';
// import Swal from 'sweetalert2';
import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx";
import SweetAlert from "react-bootstrap-sweetalert";
import buttonStyle from "assets/jss/material-dashboard-pro-react/components/buttonStyle.jsx";
import moment from "moment";

const styles = {
  ...buttonStyle,
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  }
};

class Customer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      alert: null,
    };
    this.hideAlert = this.hideAlert.bind(this);
  }
  async componentDidMount() {
    try {
      await this.props.customer.loadCustomer();
    } catch (error) {
      this.setState({
        alert: (
          <SweetAlert
            error
            style={{ display: "block", marginTop: "-100px" }}
            title={`Error !`}
            onConfirm={() => this.hideAlert()}
            confirmBtnCssClass={
              this.props.classes.button + " " + this.props.classes.success
            }
          >
           {`${error.message}`}
          </SweetAlert>
        )
      });
    }
  }


  hideAlert() {
    this.setState({
      alert: null
    });
  }

  render() {
    const { classes } = this.props;
    let { customers } = this.props.customer;
    let dataCustomer = customers.map((prop, key) => {
      return {
        no: key,
        id: prop.id,
        firstname: prop.first_name,
        lastname: prop.last_name,
        phone: prop.phone,
        email: prop.email,
        birthdate: moment(prop.birth_date).format("DD/MM/YYYY"),
        // actions: (
        //   <div className="actions-center">
        //     <Tooltip title="Edit User" placement="top">
        //       <Button
        //         justIcon
        //         round
        //         // simple
        //         onClick={() => {
        //           let obj = dataCustomer.find(o => o.no === key);
        //           this.props.history.push(`/admin/user/${obj.id}/edit`)
        //         }}
        //         color="warning"
        //         className="edit">
        //         <Edit />
        //       </Button>
        //     </Tooltip>
        //     &nbsp;&nbsp;&nbsp;
        //     <Tooltip title="Delete" placement="top">
        //       <Button
        //         justIcon
        //         round
        //         // simple
        //         onClick={() => {
        //           let obj = dataCustomer.find(o => o.no === key);
        //           this.onDeleteClick(obj.id)
        //         }}
        //         color="danger"
        //         className="remove">
        //         <Close />
        //       </Button>
        //     </Tooltip>
        //   </div>
        // )
      };
    });

    return (
      <GridContainer>
        {this.state.alert}
        <GridItem xs={12}>
          <Card>
            <CardHeader color="primary" icon>
              <CardIcon color="primary">
                <Person />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>Customer List</h4>
            </CardHeader>
            <CardBody>
              <ReactTable
                data={dataCustomer}
                filterable
                columns={[
                  {
                    Header: "ID",
                    accessor: "id",
                    width: 80
                  },
                  {
                    Header: "First Name",
                    accessor: "firstname",
                  },
                  {
                    Header: "Last Name",
                    accessor: "lastname",
                  },
                  {
                    Header: "Phone",
                    accessor: "phone",
                  },
                  {
                    Header: "Email",
                    accessor: "email"
                  },
                  {
                    Header: "Birth Date",
                    accessor: "birthdate"
                  },
                  // {
                  //   Header: "Actions",
                  //   headerStyle: { textAlign: 'center' },
                  //   accessor: "actions",
                  //   sortable: false,
                  //   filterable: false
                  // }
                ]}
                defaultPageSize={10}
                showPaginationTop={false}
                showPaginationBottom={true}
                className="-striped -highlight"
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  }
}

Customer.propTypes = {
  classes: PropTypes.object
};

export const page = inject('customer')(observer(Customer));
export default withStyles(styles, { withTheme: true })(page);