/*eslint-disable*/
import React from 'react';
import { observer, inject } from 'mobx-react';
import Select from 'react-select';
import Card from 'components/Card/Card.jsx';
import CardBody from 'components/Card/CardBody.jsx';
import CardIcon from 'components/Card/CardIcon.jsx';
import CardHeader from 'components/Card/CardHeader.jsx';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import Search from '@material-ui/icons/Search';
import GetAppIcon from '@material-ui/icons/GetApp';
import Button from 'components/CustomButtons/Button.jsx';
import DateFnsUtils from '@date-io/date-fns';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import ReactTable from 'react-table';
import Swal from 'sweetalert2';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import validationFormsStyle from 'assets/jss/material-dashboard-pro-react/views/validationFormsStyle.jsx';
import buttonStyle from 'assets/jss/material-dashboard-pro-react/components/buttonStyle.jsx';
import ExcelButton from './ExcelButton';

import { formatDate } from '../../utils/datetime';

import './report.scss';

const styles = {
  ...buttonStyle,
  ...validationFormsStyle,
  tmp: {
    backGroundColor: '#000',
  },
  buttonMain: {
    marginTop: '15px',
    padding: '10px 20px 10px 10px',
  },
};

const tableColumns = [
  { Header: 'Booking Date', accessor: 'createdAt' },
  { Header: 'Check-In', accessor: 'startDate' },
  { Header: 'Check-Out', accessor: 'endDate' },
  { Header: 'House Name', accessor: 'houseName' },
  { Header: 'Persons', accessor: 'personAmount' },
  { Header: 'Price', accessor: 'totalPrice' },
  { Header: 'Sale Name', accessor: 'saleName' },
];

const excelColumns = [
  { label: 'Booking Date', value: 'createdAt' },
  { label: 'Check-In', value: 'startDate' },
  { label: 'Check-Out', value: 'endDate' },
  { label: 'House Name', value: 'houseName' },
  { label: 'Persons', value: 'personAmount' },
  { label: 'Price', value: 'totalPrice' },
  { label: 'Sale Name', value: 'saleName' },
  { label: 'Account Name', value: 'accountName' },
  { label: 'Bank Name', value: 'bankName' },
  { label: 'Account No', value: 'accountNo' },
];

class Report extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      startDate: new Date(),
      endDate: new Date(),
      selectedHouses: [],
      createStart: null,
      createEnd: null,
      status: 'paid'
    };
  }

  changeState(key, value) {
    this.setState(prevState => ({
      ...prevState,
      [key]: value,
    }));
  }

  async componentDidMount() {
    await this.props.report.loadHouses();
    await this.props.report.searchBookings({ ...this.state });
  }

  async searchByCondition() {
    try {
      await this.props.report.searchBookings({ ...this.state });
    } catch (err) {
      await Swal.fire({
        type: 'error',
        title: err.message,
      });
    }
  }

  render() {
    const houseOptions = this.props.report.getHouseOptions();
    const data = this.props.report.getData();
    const { startDate, endDate, createStart, createEnd } = this.state;
    const { classes } = this.props;

    return (
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardHeader color="primary" icon>
              <CardIcon color="primary">
                <InsertDriveFileIcon />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>Booking Report</h4>

              <GridContainer
                style={{
                  paddingLeft: '30px',
                  paddingRight: '30px',
                  paddingTop: '30px',
                }}>
                <GridItem xs={12}>
                  <Select
                    isMulti
                    options={houseOptions}
                    onChange={value => {
                      this.changeState('selectedHouses', value);
                    }}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    placeholder="Select Houses"
                  />
                </GridItem>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <GridItem xs={12} sm={6} md={3}>
                    <KeyboardDatePicker
                      fullWidth
                      disableToolbar
                      variant="inline"
                      format="dd/MM/yyyy"
                      margin="normal"
                      id="createStart"
                      name="createStart"
                      label="Booking Start"
                      value={createStart}
                      onChange={date => this.changeState('createStart', date)}
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={6} md={3}>
                    <KeyboardDatePicker
                      fullWidth
                      disableToolbar
                      variant="inline"
                      format="dd/MM/yyyy"
                      margin="normal"
                      id="createEnd"
                      name="createEnd"
                      label="Booking End"
                      value={createEnd}
                      onChange={date => this.changeState('createEnd', date)}
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={6} md={3}>
                    <KeyboardDatePicker
                      fullWidth
                      disableToolbar
                      variant="inline"
                      format="dd/MM/yyyy"
                      margin="normal"
                      id="startDate"
                      name="startDate"
                      label="Check-In"
                      value={startDate}
                      onChange={date => this.changeState('startDate', date)}
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={6} md={3}>
                    <KeyboardDatePicker
                      fullWidth
                      disableToolbar
                      variant="inline"
                      format="dd/MM/yyyy"
                      margin="normal"
                      id="endDate"
                      name="endDate"
                      label="Check-Out"
                      value={endDate}
                      onChange={date => this.changeState('endDate', date)}
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={6} md={3}>
                    <Button
                      fullWidth
                      color="warning"
                      className={classes.marginRight + ' ' + classes.buttonMain}
                      onClick={() => this.searchByCondition()}>
                      <Search className={classes.icons} /> Search
                    </Button>
                  </GridItem>
                  <GridItem xs={12} sm={6} md={3}>
                    <ExcelButton
                      data={data || []}
                      fileName={`Booking${formatDate(
                        new Date(),
                        'YYYYMMDDHHmmss',
                      )}`}
                      sheetName="Booking Data"
                      columns={excelColumns}
                      button={
                        <Button
                          fullWidth
                          color="primary"
                          className={
                            classes.marginRight + ' ' + classes.buttonMain
                          }>
                          <GetAppIcon className={classes.icons} />
                          Export
                        </Button>
                      }
                    />
                  </GridItem>
                </MuiPickersUtilsProvider>
                {/* <GridItem>
                  <Select
                    isMulti
                    options={houseOptions}
                    onChange={value => {
                      this.changeState('selectedHouses', value);
                    }}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    placeholder="Select Houses"
                  />
                </GridItem>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <GridItem>
                    <KeyboardDatePicker
                      disableToolbar
                      variant="inline"
                      format="dd/MM/yyyy"
                      margin="normal"
                      id="startDate"
                      name="startDate"
                      label="Start Date"
                      value={startDate}
                      onChange={date => this.changeState('startDate', date)}
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                    />
                  </GridItem>
                  <GridItem>
                    <KeyboardDatePicker
                      disableToolbar
                      variant="inline"
                      format="dd/MM/yyyy"
                      margin="normal"
                      id="endDate"
                      name="endDate"
                      label="End Date"
                      value={endDate}
                      onChange={date => this.changeState('endDate', date)}
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                    />
                  </GridItem>
                  <GridItem>
                    <Button
                      variant="contained"
                      color="primary"
                      className={classes.button}
                      endicon={<Icon>send</Icon>}
                      onClick={() => this.searchByCondition()}>
                      Search
                    </Button>
                  </GridItem>
                  <GridItem>
                    <CardIcon
                      color="info"
                      style={{
                        float: 'right',
                        borderRadius: '35px',
                        cursor: 'pointer',
                      }}>
                      <ExcelButton
                        label={column.map(val => {
                          return {
                            name: val.Header,
                            value: val.accessor,
                          };
                        })}
                        data={[...data]}
                        name="Booking Report"
                      />
                    </CardIcon>
                  </GridItem>
                </MuiPickersUtilsProvider>
              */}
              </GridContainer>
            </CardHeader>
            <CardBody>
              <ReactTable
                data={data || []}
                filterable
                columns={tableColumns}
                defaultPageSize={10}
                showPaginationTop={false}
                showPaginationBottom={true}
                className="-striped -highlight"
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  }
}

Report.propTypes = {
  classes: PropTypes.object,
};

export const page = inject('report')(observer(Report));
export default withStyles(styles)(page);
