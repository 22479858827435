import React from 'react';
import { useMediaQuery, Box, IconButton } from '@material-ui/core';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import CardHeader from 'components/Card/CardHeader.jsx';

import CardBody from 'components/Card/CardBody';
import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/EditOutlined';

import '../styles/house_icon.css';
import Card from 'components/Card/Card';
import withStyles from '@material-ui/core/styles/withStyles';
import Tooltip from '@material-ui/core/Tooltip';
import dashboardStyle from 'assets/jss/material-dashboard-pro-react/views/dashboardStyle';

const ImageIcon = ({ images, classes, onIconEditClick, onIconDeleteClick }) => {
  const xs = useMediaQuery('(max-width:500px)');
  const sm = useMediaQuery('(max-width:700px)');
  const md = useMediaQuery('(max-width:900px)');
  const md2 = useMediaQuery('(max-width:1100px)');
  const lg = useMediaQuery('(max-width:1300px)');

  let displayItem;

  if(images.length > 7){
    if (xs) {
      displayItem = 2;
    } else if (sm) {
      displayItem = 3;
    } else if (md) {
      displayItem = 4;
    } else if (md2) {
      displayItem = 4;
    } else if (lg) {
      displayItem = 5;
    } else {
        displayItem = 7;
    }
  } else {
    displayItem = images.length;
  }

  return (
    <React.Fragment>
      <GridContainer>
        <GridItem>
          <Box width="100%">
            {images.map((item, index) => {
              return (
                <Box key={index} width={1 / displayItem} display="inline-block">
                  <div style={{ padding: '10px' }}>
                    <Card product className={classes.cardHover}>
                      <CardHeader
                        image
                        className={classes.cardHeaderHover}
                        style={{ backgroundColor: 'white' }}>
                        <a href="#pablo" onClick={e => e.preventDefault()}>
                          <img src={item.url} alt="..." />
                        </a>
                      </CardHeader>
                      <CardBody>
                        <div className={classes.cardHoverUnder}>
                          <Tooltip
                            id="tooltip-top"
                            title="แก้ไข"
                            placement="bottom"
                            classes={{ tooltip: classes.tooltip }}>
                            <IconButton
                              aria-label="edit"
                              onClick={() => onIconEditClick(item)}>
                              <EditIcon
                                style={{ color: 'orange', fontSize: '16px' }}
                              />
                            </IconButton>
                          </Tooltip>
                          <Tooltip
                            id="tooltip-top"
                            title="ลบ"
                            placement="bottom"
                            classes={{ tooltip: classes.tooltip }}>
                            <IconButton
                              aria-label="delete"
                              onClick={() =>
                                onIconDeleteClick(item.id, item.description)
                              }>
                              <CloseIcon
                                style={{ color: 'red', fontSize: '16px' }}
                              />
                            </IconButton>
                          </Tooltip>
                        </div>
                        <span>{item.description || ''}</span>
                      </CardBody>
                    </Card>
                  </div>
                </Box>
              );
            })}
          </Box>
        </GridItem>
      </GridContainer>
    </React.Fragment>
  );
};

export default withStyles(dashboardStyle)(ImageIcon);

// export default ImageIcon;
